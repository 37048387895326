import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";
import { Information } from "../components/Information";

const DutchInformationPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Informatie" lang="nl" pathName={location.pathname} />
    <Information language="nl" />
  </>
);

export default DutchInformationPage;
